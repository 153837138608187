import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { AppRoutingModule } from './../../app-routing.module';

@Component({
	// moduleId: module.id,
    selector: 'app-example-layout',
	templateUrl: "./examplelayout.component.html",
	styleUrls: ['./examplelayout.component.scss']
})
export class ExampleLayout implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}