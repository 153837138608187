import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import layouts
import { ExampleLayout } from "./layouts/ExampleLayout/examplelayout.component";
import { AccountLayout } from "./layouts/AccountLayout/accountlayout.component";
import { DashboardLayout } from "./layouts/DashboardLayout/dashboardlayout.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/HomePage/home.module').then(m => m.HomePageModule),
  },
    {
      // Pages which inherit from AccountLayout
      path: "",
      component: AccountLayout,
      children: [
        { path: "register", loadChildren: () => import('./pages/RegisterPage/register.module').then(m => m.RegisterPageModule) },
        { path: "sign-in", loadChildren: () => import('./pages/SignInPage/signin.module').then(m => m.SignInPageModule) },
        { path: "email-verif", loadChildren: () => import('./pages/EmailVerificationPage/emailverif.module').then(m => m.EmailVerifPageModule) },
        { path: "forgot-pass", loadChildren: () => import('./pages/ForgottenPasswordPage/forgottenpassword.module').then(m => m.ForgottenPasswordPageModule) },
        { path: "forgot-pass-complete", loadChildren: () => import('./pages/ForgottenPasswordCompletePage/forgottenpasswordcomplete.module').then(m => m.ForgottenPasswordCompletePageModule) }
      ]
  },
  {
    // Pages which inherit from AccountLayout
    path: "",
    component: DashboardLayout,
    children: [
      { path: "dashboard", loadChildren: () => import('./pages/DashboardPage/dashboard.module').then(m => m.DashboardPageModule) },
      { path: "add-server", loadChildren: () => import('./pages/AddServerPage/addserver.module').then(m => m.AddServerPageModule) },
      { path: "discordauth", loadChildren: () => import('./pages/DiscordAuthPage/discordauth.module').then(m => m.DiscordAuthPageModule) },
      { path: "manage-server", loadChildren: () => import('./pages/ManageServerPage/manageserver.module').then(m => m.ManageServerPageModule) }

    ]
},


  // Example pages
  { path: 'examples/color', loadChildren: () => import('./pages/Examples/ColorExamplePage/color-example-page.module').then(m => m.ColorExamplePageModule) },
  { path: 'examples/components', loadChildren: () => import('./pages/Examples/ComponentsExamplePage/components-example-page.module').then(m => m.ComponentsExamplePageModule) },
  { path: 'examples/eventbindings', loadChildren: () => import('./pages/Examples/EventBindingsExamplePage/event-bindings-example-page.module').then(m => m.EventBindingsExamplePageModule) },
  { path: 'examples/cookies', loadChildren: () => import('./pages/Examples/CookiesExamplePage/cookies-example-page.module').then(m => m.CookiesExamplePageModule) },
  { path: 'examples/network', loadChildren: () => import('./pages/Examples/NetworkExamplePage/network-example-page.module').then(m => m.NetworkExamplePageModule) },
  {
        // Pages which inherit from ExampleLayout
        path: "",
        component: ExampleLayout,
        children: [
            { path: "examples/layouts", loadChildren: () => import('./pages/Examples/LayoutExamplePage/layout-example-page.module').then(m => m.LayoutExamplePageModule) }
        ]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
