import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class CookieService {

    /**
     * Redirects to other pages if cookies are wrong.
     * @param router The angular router.
     * @param currentRoute The current route. Absolute. No leading slash.
     * @returns True if redirection occurs.
     */
    public Redirect(router: Router, currentRoute: string): boolean {

        if ((currentRoute != "") && (currentRoute != "register") && (currentRoute != "sign-in") && (currentRoute != "forgot-pass") && (currentRoute != "forgot-pass-complete")) {
            if (this.isStringNullOrEmpty(this.GetValue("AuthToken"))) {
                router.navigate(["/sign-in"]);
                return true;
            } else {
                if ((this.GetValue("EmailVerified") != "true") && (currentRoute != "email-verif")) {
                    router.navigate(["/sign-in"]);
                    return true;
                }

                if ((this.GetValue("EmailVerified") == "true") && (currentRoute == "email-verif")) {
                    router.navigate(["/dashboard"]);
                    return true;
                }
            }
        }

        if (currentRoute == "forgot-pass-complete") {
            if (this.isStringNullOrEmpty(this.GetValue("ForgotPassAuthToken"))) {
                router.navigate(["/forgot-pass"]);
                return true;
            }
        }

        if (currentRoute == "forgot-pass") {
            if (!this.isStringNullOrEmpty(this.GetValue("ForgotPassAuthToken"))) {
                router.navigate(["/forgot-pass-complete"]);
                return true;
            }
        }

        if ((currentRoute == "") || (currentRoute == "sign-in") || (currentRoute == "register")|| (currentRoute == "forgot-pass")|| (currentRoute == "forgot-pass-complete")) {
            if (!this.isStringNullOrEmpty(this.GetValue("AuthToken"))) {
                if (this.GetValue("EmailVerified") == "true") {
                    router.navigate(["/dashboard"]);
                    return true;
                }
                if (this.GetValue("EmailVerified") == "false") {
                    router.navigate(["/email-verif"]);
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Sets value in cookie dictionary
     * @param {string} key   Dictionary key
     * @param {string} value Dictionary value to set
     */
    public SetValue(key: string, value: string): void {
        window.localStorage.setItem(key, value);
    }

    /**
     * Retrieves value from cookie dictionary
     * @param  {string} key Key to get value from
     * @return {string}     Value corresponding to given key. Empty string if null.
     */
    public GetValue(key: string) : string {
        let item = window.localStorage.getItem(key);
        if (item == null) {
            return "";
        }
        return item;
    }

    /**
     * Removes entry from cookie dictionary
     * @param  {string}  key Key of entry to remove
     * @return {boolean}     True if successfully removed, false if didn't exist
     */
    public RemoveValue(key: string): boolean {
        let item = window.localStorage.getItem(key);
        if (item == null) {
            return false;
        }
        window.localStorage.removeItem(key);
        return true;
    }

    /**
     * Removes all values from cookie dictionary
     */
    public ClearAll(): void {
        window.localStorage.clear();
    }

    private isStringNullOrEmpty(value: string): boolean {
        if (!value) {
            return true;
        }
        return value == "";
    }
}