import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { AppRoutingModule } from './../../app-routing.module';
import { CookieService } from '../../services/CookieService';
import { Router } from '@angular/router';

@Component({
	// moduleId: module.id,
    selector: 'app-dashboard-layout',
	templateUrl: "./dashboardlayout.component.html",
	styleUrls: ['./dashboardlayout.component.scss']
})
export class DashboardLayout implements OnInit {

	constructor(private cookieService: CookieService, private router: Router) {
	}

	ngOnInit(): void {
	}

	signOutPressed(): void {
		this.cookieService.ClearAll();
		this.router.navigate(["/sign-in"]);
	}
}