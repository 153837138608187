<div class="account-page flex-col-container">

    <!-- Title Bar -->
    <div class="title-bar flex-row-container">
        <div class="flex-col-container">
            <div class="title">
                Disomate
            </div>
        </div>
        <div class="flex-grow"></div>
        <a class="title-link" href="/">
            Home
        </a>
        <a class="title-link" href="/sign-in">
            Sign In
        </a>
        <a class="title-link" href="/register">
            Create Account
        </a>
    </div>

    <div class="flex-grow"></div>
    <div class="flex-row-container">
        <div class="flex-grow"></div>
        <div class="container">
            <router-outlet></router-outlet>
        </div>
        <div class="flex-grow"></div>
    </div>
    <div class="flex-grow-2"></div>

</div>