<div class="dashboard-page">

    <!-- Title Bar -->
    <div class="title-bar flex-row-container">
        <div class="flex-col-container">
            <div class="title">
                Disomate
            </div>
        </div>
        <div class="flex-grow"></div>
        <a class="title-link" href="/dashboard">
            Dashboard
        </a>
        <button class="title-link" (click)="signOutPressed()">
            Sign Out
        </button>
    </div>

    <router-outlet></router-outlet>
</div>